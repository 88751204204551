export const timezones = [
  {name: "Pacific/Niue", gmt: "(GMT-11:00) Niue"},
  {name: "Pacific/Pago_Pago", gmt: "(GMT-11:00) Pago Pago"},
  {name: "Pacific/Honolulu", gmt: "(GMT-10:00) Hawaii Time"},
  {name: "Pacific/Rarotonga", gmt: "(GMT-10:00) Rarotonga"},
  {name: "Pacific/Tahiti", gmt: "(GMT-10:00) Tahiti"},
  {name: "Pacific/Marquesas", gmt: "(GMT-09:30) Marquesas"},
  {name: "America/Anchorage", gmt: "(GMT-09:00) Alaska Time"},
  {name: "Pacific/Gambier", gmt: "(GMT-09:00) Gambier"},
  {name: "America/Los_Angeles", gmt: "(GMT-08:00) Pacific Time"},
  {name: "America/Tijuana", gmt: "(GMT-08:00) Pacific Time - Tijuana"},
  {name: "America/Vancouver", gmt: "(GMT-08:00) Pacific Time - Vancouver"},
  {name: "America/Whitehorse", gmt: "(GMT-08:00) Pacific Time - Whitehorse"},
  {name: "Pacific/Pitcairn", gmt: "(GMT-08:00) Pitcairn"},
  {name: "America/Dawson_Creek", gmt: "(GMT-07:00) Mountain Time - Dawson Creek"},
  {name: "America/Denver", gmt: "(GMT-07:00) Mountain Time"},
  {name: "America/Edmonton", gmt: "(GMT-07:00) Mountain Time - Edmonton"},
  {name: "America/Hermosillo", gmt: "(GMT-07:00) Mountain Time - Hermosillo"},
  {name: "America/Mazatlan", gmt: "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan"},
  {name: "America/Phoenix", gmt: "(GMT-07:00) Mountain Time - Arizona"},
  {name: "America/Yellowknife", gmt: "(GMT-07:00) Mountain Time - Yellowknife"},
  {name: "America/Belize", gmt: "(GMT-06:00) Belize"},
  {name: "America/Chicago", gmt: "(GMT-06:00) Central Time"},
  {name: "America/Costa_Rica", gmt: "(GMT-06:00) Costa Rica"},
  {name: "America/El_Salvador", gmt: "(GMT-06:00) El Salvador"},
  {name: "America/Guatemala", gmt: "(GMT-06:00) Guatemala"},
  {name: "America/Managua", gmt: "(GMT-06:00) Managua"},
  {name: "America/Mexico_City", gmt: "(GMT-06:00) Central Time - Mexico City"},
  {name: "America/Regina", gmt: "(GMT-06:00) Central Time - Regina"},
  {name: "America/Tegucigalpa", gmt: "(GMT-06:00) Central Time - Tegucigalpa"},
  {name: "America/Winnipeg", gmt: "(GMT-06:00) Central Time - Winnipeg"},
  {name: "Pacific/Galapagos", gmt: "(GMT-06:00) Galapagos"},
  {name: "America/Bogota", gmt: "(GMT-05:00) Bogota"},
  {name: "America/Cancun", gmt: "(GMT-05:00) America Cancun"},
  {name: "America/Cayman", gmt: "(GMT-05:00) Cayman"},
  {name: "America/Guayaquil", gmt: "(GMT-05:00) Guayaquil"},
  {name: "America/Havana", gmt: "(GMT-05:00) Havana"},
  {name: "America/Iqaluit", gmt: "(GMT-05:00) Eastern Time - Iqaluit"},
  {name: "America/Jamaica", gmt: "(GMT-05:00) Jamaica"},
  {name: "America/Lima", gmt: "(GMT-05:00) Lima"},
  {name: "America/Nassau", gmt: "(GMT-05:00) Nassau"},
  {name: "America/New_York", gmt: "(GMT-05:00) Eastern Time"},
  {name: "America/Panama", gmt: "(GMT-05:00) Panama"},
  {name: "America/Port-au-Prince", gmt: "(GMT-05:00) Port-au-Prince"},
  {name: "America/Rio_Branco", gmt: "(GMT-05:00) Rio Branco"},
  {name: "America/Toronto", gmt: "(GMT-05:00) Eastern Time - Toronto"},
  {name: "Pacific/Easter", gmt: "(GMT-05:00) Easter Island"},
  {name: "America/Caracas", gmt: "(GMT-04:30) Caracas"},
  {name: "America/Asuncion", gmt: "(GMT-03:00) Asuncion"},
  {name: "America/Barbados", gmt: "(GMT-04:00) Barbados"},
  {name: "America/Boa_Vista", gmt: "(GMT-04:00) Boa Vista"},
  {name: "America/Campo_Grande", gmt: "(GMT-03:00) Campo Grande"},
  {name: "America/Cuiaba", gmt: "(GMT-03:00) Cuiaba"},
  {name: "America/Curacao", gmt: "(GMT-04:00) Curacao"},
  {name: "America/Grand_Turk", gmt: "(GMT-04:00) Grand Turk"},
  {name: "America/Guyana", gmt: "(GMT-04:00) Guyana"},
  {name: "America/Halifax", gmt: "(GMT-04:00) Atlantic Time - Halifax"},
  {name: "America/La_Paz", gmt: "(GMT-04:00) La Paz"},
  {name: "America/Manaus", gmt: "(GMT-04:00) Manaus"},
  {name: "America/Martinique", gmt: "(GMT-04:00) Martinique"},
  {name: "America/Port_of_Spain", gmt: "(GMT-04:00) Port of Spain"},
  {name: "America/Porto_Velho", gmt: "(GMT-04:00) Porto Velho"},
  {name: "America/Puerto_Rico", gmt: "(GMT-04:00) Puerto Rico"},
  {name: "America/Santo_Domingo", gmt: "(GMT-04:00) Santo Domingo"},
  {name: "America/Thule", gmt: "(GMT-04:00) Thule"},
  {name: "Atlantic/Bermuda", gmt: "(GMT-04:00) Bermuda"},
  {name: "America/St_Johns", gmt: "(GMT-03:30) Newfoundland Time - St. Johns"},
  {name: "America/Araguaina", gmt: "(GMT-03:00) Araguaina"},
  {name: "America/Argentina/Buenos_Aires", gmt: "(GMT-03:00) Buenos Aires"},
  {name: "America/Bahia", gmt: "(GMT-03:00) Salvador"},
  {name: "America/Belem", gmt: "(GMT-03:00) Belem"},
  {name: "America/Cayenne", gmt: "(GMT-03:00) Cayenne"},
  {name: "America/Fortaleza", gmt: "(GMT-03:00) Fortaleza"},
  {name: "America/Godthab", gmt: "(GMT-03:00) Godthab"},
  {name: "America/Maceio", gmt: "(GMT-03:00) Maceio"},
  {name: "America/Miquelon", gmt: "(GMT-03:00) Miquelon"},
  {name: "America/Montevideo", gmt: "(GMT-03:00) Montevideo"},
  {name: "America/Paramaribo", gmt: "(GMT-03:00) Paramaribo"},
  {name: "America/Recife", gmt: "(GMT-03:00) Recife"},
  {name: "America/Santiago", gmt: "(GMT-03:00) Santiago"},
  {name: "America/Sao_Paulo", gmt: "(GMT-02:00) Sao Paulo"},
  {name: "Antarctica/Palmer", gmt: "(GMT-03:00) Palmer"},
  {name: "Antarctica/Rothera", gmt: "(GMT-03:00) Rothera"},
  {name: "Atlantic/Stanley", gmt: "(GMT-03:00) Stanley"},
  {name: "America/Noronha", gmt: "(GMT-02:00) Noronha"},
  {name: "Atlantic/South_Georgia", gmt: "(GMT-02:00) South Georgia"},
  {name: "America/Scoresbysund", gmt: "(GMT-01:00) Scoresbysund"},
  {name: "Atlantic/Azores", gmt: "(GMT-01:00) Azores"},
  {name: "Atlantic/Cape_Verde", gmt: "(GMT-01:00) Cape Verde"},
  {name: "Africa/Abidjan", gmt: "(GMT+00:00) Abidjan"},
  {name: "Africa/Accra", gmt: "(GMT+00:00) Accra"},
  {name: "Africa/Bissau", gmt: "(GMT+00:00) Bissau"},
  {name: "Africa/Casablanca", gmt: "(GMT+00:00) Casablanca"},
  {name: "Africa/El_Aaiun", gmt: "(GMT+00:00) El Aaiun"},
  {name: "Africa/Monrovia", gmt: "(GMT+00:00) Monrovia"},
  {name: "America/Danmarkshavn", gmt: "(GMT+00:00) Danmarkshavn"},
  {name: "Atlantic/Canary", gmt: "(GMT+00:00) Canary Islands"},
  {name: "Atlantic/Faroe", gmt: "(GMT+00:00) Faeroe"},
  {name: "Atlantic/Reykjavik", gmt: "(GMT+00:00) Reykjavik"},
  {name: "Etc/GMT", gmt: "(GMT+00:00) GMT (no daylight saving)"},
  {name: "Europe/Dublin", gmt: "(GMT+00:00) Dublin"},
  {name: "Europe/Lisbon", gmt: "(GMT+00:00) Lisbon"},
  {name: "Europe/London", gmt: "(GMT+00:00) London"},
  {name: "Africa/Algiers", gmt: "(GMT+01:00) Algiers"},
  {name: "Africa/Ceuta", gmt: "(GMT+01:00) Ceuta"},
  {name: "Africa/Lagos", gmt: "(GMT+01:00) Lagos"},
  {name: "Africa/Ndjamena", gmt: "(GMT+01:00) Ndjamena"},
  {name: "Africa/Tunis", gmt: "(GMT+01:00) Tunis"},
  {name: "Africa/Windhoek", gmt: "(GMT+02:00) Windhoek"},
  {name: "Europe/Amsterdam", gmt: "(GMT+01:00) Amsterdam"},
  {name: "Europe/Andorra", gmt: "(GMT+01:00) Andorra"},
  {name: "Europe/Belgrade", gmt: "(GMT+01:00) Central European Time - Belgrade"},
  {name: "Europe/Berlin", gmt: "(GMT+01:00) Berlin"},
  {name: "Europe/Brussels", gmt: "(GMT+01:00) Brussels"},
  {name: "Europe/Budapest", gmt: "(GMT+01:00) Budapest"},
  {name: "Europe/Copenhagen", gmt: "(GMT+01:00) Copenhagen"},
  {name: "Europe/Gibraltar", gmt: "(GMT+01:00) Gibraltar"},
  {name: "Europe/Luxembourg", gmt: "(GMT+01:00) Luxembourg"},
  {name: "Europe/Madrid", gmt: "(GMT+01:00) Madrid"},
  {name: "Europe/Malta", gmt: "(GMT+01:00) Malta"},
  {name: "Europe/Monaco", gmt: "(GMT+01:00) Monaco"},
  {name: "Europe/Oslo", gmt: "(GMT+01:00) Oslo"},
  {name: "Europe/Paris", gmt: "(GMT+01:00) Paris"},
  {name: "Europe/Prague", gmt: "(GMT+01:00) Central European Time - Prague"},
  {name: "Europe/Rome", gmt: "(GMT+01:00) Rome"},
  {name: "Europe/Stockholm", gmt: "(GMT+01:00) Stockholm"},
  {name: "Europe/Tirane", gmt: "(GMT+01:00) Tirane"},
  {name: "Europe/Vienna", gmt: "(GMT+01:00) Vienna"},
  {name: "Europe/Warsaw", gmt: "(GMT+01:00) Warsaw"},
  {name: "Europe/Zurich", gmt: "(GMT+01:00) Zurich"},
  {name: "Africa/Cairo", gmt: "(GMT+02:00) Cairo"},
  {name: "Africa/Johannesburg", gmt: "(GMT+02:00) Johannesburg"},
  {name: "Africa/Maputo", gmt: "(GMT+02:00) Maputo"},
  {name: "Africa/Tripoli", gmt: "(GMT+02:00) Tripoli"},
  {name: "Asia/Amman", gmt: "(GMT+02:00) Amman"},
  {name: "Asia/Beirut", gmt: "(GMT+02:00) Beirut"},
  {name: "Asia/Damascus", gmt: "(GMT+02:00) Damascus"},
  {name: "Asia/Gaza", gmt: "(GMT+02:00) Gaza"},
  {name: "Asia/Jerusalem", gmt: "(GMT+02:00) Jerusalem"},
  {name: "Asia/Nicosia", gmt: "(GMT+02:00) Nicosia"},
  {name: "Europe/Athens", gmt: "(GMT+02:00) Athens"},
  {name: "Europe/Bucharest", gmt: "(GMT+02:00) Bucharest"},
  {name: "Europe/Chisinau", gmt: "(GMT+02:00) Chisinau"},
  {name: "Europe/Helsinki", gmt: "(GMT+02:00) Helsinki"},
  {name: "Europe/Istanbul", gmt: "(GMT+02:00) Istanbul"},
  {name: "Europe/Kaliningrad", gmt: "(GMT+02:00) Moscow-01 - Kaliningrad"},
  {name: "Europe/Kiev", gmt: "(GMT+02:00) Kiev"},
  {name: "Europe/Riga", gmt: "(GMT+02:00) Riga"},
  {name: "Europe/Sofia", gmt: "(GMT+02:00) Sofia"},
  {name: "Europe/Tallinn", gmt: "(GMT+02:00) Tallinn"},
  {name: "Europe/Vilnius", gmt: "(GMT+02:00) Vilnius"},
  {name: "Africa/Khartoum", gmt: "(GMT+03:00) Khartoum"},
  {name: "Africa/Nairobi", gmt: "(GMT+03:00) Nairobi"},
  {name: "Antarctica/Syowa", gmt: "(GMT+03:00) Syowa"},
  {name: "Asia/Baghdad", gmt: "(GMT+03:00) Baghdad"},
  {name: "Asia/Qatar", gmt: "(GMT+03:00) Qatar"},
  {name: "Asia/Riyadh", gmt: "(GMT+03:00) Riyadh"},
  {name: "Europe/Minsk", gmt: "(GMT+03:00) Minsk"},
  {name: "Europe/Moscow", gmt: "(GMT+03:00) Moscow+00 - Moscow"},
  {name: "Asia/Tehran", gmt: "(GMT+03:30) Tehran"},
  {name: "Asia/Baku", gmt: "(GMT+04:00) Baku"},
  {name: "Asia/Dubai", gmt: "(GMT+04:00) Dubai"},
  {name: "Asia/Tbilisi", gmt: "(GMT+04:00) Tbilisi"},
  {name: "Asia/Yerevan", gmt: "(GMT+04:00) Yerevan"},
  {name: "Europe/Samara", gmt: "(GMT+04:00) Moscow+01 - Samara"},
  {name: "Indian/Mahe", gmt: "(GMT+04:00) Mahe"},
  {name: "Indian/Mauritius", gmt: "(GMT+04:00) Mauritius"},
  {name: "Indian/Reunion", gmt: "(GMT+04:00) Reunion"},
  {name: "Asia/Kabul", gmt: "(GMT+04:30) Kabul"},
  {name: "Antarctica/Mawson", gmt: "(GMT+05:00) Mawson"},
  {name: "Asia/Aqtau", gmt: "(GMT+05:00) Aqtau"},
  {name: "Asia/Aqtobe", gmt: "(GMT+05:00) Aqtobe"},
  {name: "Asia/Ashgabat", gmt: "(GMT+05:00) Ashgabat"},
  {name: "Asia/Dushanbe", gmt: "(GMT+05:00) Dushanbe"},
  {name: "Asia/Karachi", gmt: "(GMT+05:00) Karachi"},
  {name: "Asia/Tashkent", gmt: "(GMT+05:00) Tashkent"},
  {name: "Asia/Yekaterinburg", gmt: "(GMT+05:00) Moscow+02 - Yekaterinburg"},
  {name: "Indian/Kerguelen", gmt: "(GMT+05:00) Kerguelen"},
  {name: "Indian/Maldives", gmt: "(GMT+05:00) Maldives"},
  {name: "Asia/Calcutta", gmt: "(GMT+05:30) India Standard Time"},
  {name: "Asia/Colombo", gmt: "(GMT+05:30) Colombo"},
  {name: "Asia/Katmandu", gmt: "(GMT+05:45) Katmandu"},
  {name: "Antarctica/Vostok", gmt: "(GMT+06:00) Vostok"},
  {name: "Asia/Almaty", gmt: "(GMT+06:00) Almaty"},
  {name: "Asia/Bishkek", gmt: "(GMT+06:00) Bishkek"},
  {name: "Asia/Dhaka", gmt: "(GMT+06:00) Dhaka"},
  {name: "Asia/Omsk", gmt: "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk"},
  {name: "Asia/Thimphu", gmt: "(GMT+06:00) Thimphu"},
  {name: "Indian/Chagos", gmt: "(GMT+06:00) Chagos"},
  {name: "Asia/Rangoon", gmt: "(GMT+06:30) Rangoon"},
  {name: "Indian/Cocos", gmt: "(GMT+06:30) Cocos"},
  {name: "Antarctica/Davis", gmt: "(GMT+07:00) Davis"},
  {name: "Asia/Bangkok", gmt: "(GMT+07:00) Bangkok"},
  {name: "Asia/Hovd", gmt: "(GMT+07:00) Hovd"},
  {name: "Asia/Jakarta", gmt: "(GMT+07:00) Jakarta"},
  {name: "Asia/Krasnoyarsk", gmt: "(GMT+07:00) Moscow+04 - Krasnoyarsk"},
  {name: "Asia/Saigon", gmt: "(GMT+07:00) Hanoi"},
  {name: "Asia/Ho_Chi_Minh", gmt: "(GMT+07:00) Ho Chi Minh"},
  {name: "Indian/Christmas", gmt: "(GMT+07:00) Christmas"},
  {name: "Antarctica/Casey", gmt: "(GMT+08:00) Casey"},
  {name: "Asia/Brunei", gmt: "(GMT+08:00) Brunei"},
  {name: "Asia/Choibalsan", gmt: "(GMT+08:00) Choibalsan"},
  {name: "Asia/Hong_Kong", gmt: "(GMT+08:00) Hong Kong"},
  {name: "Asia/Irkutsk", gmt: "(GMT+08:00) Moscow+05 - Irkutsk"},
  {name: "Asia/Kuala_Lumpur", gmt: "(GMT+08:00) Kuala Lumpur"},
  {name: "Asia/Macau", gmt: "(GMT+08:00) Macau"},
  {name: "Asia/Makassar", gmt: "(GMT+08:00) Makassar"},
  {name: "Asia/Manila", gmt: "(GMT+08:00) Manila"},
  {name: "Asia/Shanghai", gmt: "(GMT+08:00) China Time - Beijing"},
  {name: "Asia/Singapore", gmt: "(GMT+08:00) Singapore"},
  {name: "Asia/Taipei", gmt: "(GMT+08:00) Taipei"},
  {name: "Asia/Ulaanbaatar", gmt: "(GMT+08:00) Ulaanbaatar"},
  {name: "Australia/Perth", gmt: "(GMT+08:00) Western Time - Perth"},
  {name: "Asia/Pyongyang", gmt: "(GMT+08:30) Pyongyang"},
  {name: "Asia/Dili", gmt: "(GMT+09:00) Dili"},
  {name: "Asia/Jayapura", gmt: "(GMT+09:00) Jayapura"},
  {name: "Asia/Seoul", gmt: "(GMT+09:00) Seoul"},
  {name: "Asia/Tokyo", gmt: "(GMT+09:00) Tokyo"},
  {name: "Asia/Yakutsk", gmt: "(GMT+09:00) Moscow+06 - Yakutsk"},
  {name: "Pacific/Palau", gmt: "(GMT+09:00) Palau"},
  {name: "Australia/Adelaide", gmt: "(GMT+10:30) Central Time - Adelaide"},
  {name: "Australia/Darwin", gmt: "(GMT+09:30) Central Time - Darwin"},
  {name: "Antarctica/DumontDUrville", gmt: "(GMT+10:00) Dumont D'Urville"},
  {name: "Asia/Magadan", gmt: "(GMT+10:00) Moscow+07 - Magadan"},
  {name: "Asia/Vladivostok", gmt: "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk"},
  {name: "Australia/Brisbane", gmt: "(GMT+10:00) Eastern Time - Brisbane"},
  {name: "Australia/Hobart", gmt: "(GMT+11:00) Eastern Time - Hobart"},
  {name: "Australia/Sydney", gmt: "(GMT+11:00) Eastern Time - Melbourne, Sydney"},
  {name: "Pacific/Chuuk", gmt: "(GMT+10:00) Truk"},
  {name: "Pacific/Guam", gmt: "(GMT+10:00) Guam"},
  {name: "Pacific/Port_Moresby", gmt: "(GMT+10:00) Port Moresby"},
  {name: "Pacific/Efate", gmt: "(GMT+11:00) Efate"},
  {name: "Pacific/Guadalcanal", gmt: "(GMT+11:00) Guadalcanal"},
  {name: "Pacific/Kosrae", gmt: "(GMT+11:00) Kosrae"},
  {name: "Pacific/Norfolk", gmt: "(GMT+11:00) Norfolk"},
  {name: "Pacific/Noumea", gmt: "(GMT+11:00) Noumea"},
  {name: "Pacific/Pohnpei", gmt: "(GMT+11:00) Ponape"},
  {name: "Asia/Kamchatka", gmt: "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy"},
  {name: "Pacific/Auckland", gmt: "(GMT+13:00) Auckland"},
  {name: "Pacific/Fiji", gmt: "(GMT+13:00) Fiji"},
  {name: "Pacific/Funafuti", gmt: "(GMT+12:00) Funafuti"},
  {name: "Pacific/Kwajalein", gmt: "(GMT+12:00) Kwajalein"},
  {name: "Pacific/Majuro", gmt: "(GMT+12:00) Majuro"},
  {name: "Pacific/Nauru", gmt: "(GMT+12:00) Nauru"},
  {name: "Pacific/Tarawa", gmt: "(GMT+12:00) Tarawa"},
  {name: "Pacific/Wake", gmt: "(GMT+12:00) Wake"},
  {name: "Pacific/Wallis", gmt: "(GMT+12:00) Wallis"},
  {name: "Pacific/Apia", gmt: "(GMT+14:00) Apia"},
  {name: "Pacific/Enderbury", gmt: "(GMT+13:00) Enderbury"},
  {name: "Pacific/Fakaofo", gmt: "(GMT+13:00) Fakaofo"},
  {name: "Pacific/Tongatapu", gmt: "(GMT+13:00) Tongatapu"},
  {name: "Pacific/Kiritimati", gmt: "(GMT+14:00) Kiritimati"}
];
